// components
import LineChart from "../../components/LineChart/LineChart";

// logos
import attacksBlocked from "../../assets/attacksBlocked.png";

// css
import "./AttacksChart.css";

function AttacksChart({ analyticsData }) {
    const dateLabels = analyticsData.map((data) => data.dimensions.date);
    const fillColor = "rgba(27, 58, 209, 0.8)";
    const totalAttacks = analyticsData.map((data) => data.sum.threats);
    const sumAttacks = totalAttacks.reduce(
        (prev, current) => prev + current,
        0
    );

    return (
        <div className={"attacks-graph"}>
            <h3 className={"attacks-graph__title"}>Attacks</h3>
            <div className={"attacks-graph__content"}>
                <LineChart
                    analyticsData={totalAttacks}
                    dateLabels={dateLabels}
                    icon={attacksBlocked}
                    labelName={"Attacks Blocked"}
                    fillColor={fillColor}
                    sumInfo={sumAttacks}
                    info={
                        "Number of attacks made to the application that have been blocked"
                    }
                />
            </div>
        </div>
    );
}

export default AttacksChart;
