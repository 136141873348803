// components
import VerticalBarChart from "../../components/VerticalBarChart/VerticalBarChart";

// utils
import {
    reduceCountriesValues,
    formatWithFlagAndFullName,
    topFiveSortedThreats,
} from "../../utils/supportFunctions/supportFunctions";

// icons
import attacksBlocked from "../../assets/attacksBlocked.png";

// css
import "./ThreatsBarChart.css";

function ThreatsBarChart({ analyticsData }) {
    const dailyCountriesArray = analyticsData.map(
        (data) => data.sum.countryMap
    );

    const countriesWithMostRequests =
        reduceCountriesValues(dailyCountriesArray);

    const sortedCountries = topFiveSortedThreats(countriesWithMostRequests);

    const formattedCountries = formatWithFlagAndFullName(sortedCountries);

    const labels = formattedCountries.map((country) => {
        return country.clientCountryName;
    });

    const threats = sortedCountries.map((country) => {
        return country.threats;
    });

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                display: false,
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
            },
            y: {
                display: false,
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
            },
        },
    };

    return (
        <div className={"threats-bar-graph"}>
            <h3 className={"threats-bar-graph__title"}>Threats</h3>
            <div className={"threats-bar-graph__content"}>
                <VerticalBarChart
                    threats={threats}
                    options={options}
                    labels={labels}
                    title={labels[0]}
                    value={threats[0]}
                    icon={attacksBlocked}
                    info={"Countries with the most attacks"}
                />
            </div>
        </div>
    );
}

export default ThreatsBarChart;
