// components
import BasicTable from "../../components/BasicTable/BasicTable";

// utils
import {
    reduceCountriesValues,
    formatWithFlagAndFullName,
    topFiveSortedRequests,
} from "../../utils/supportFunctions/supportFunctions";

// css
import "./RequestsTable.css";

function RequestsTable({ analyticsData }) {
    const dailyCountriesArray = analyticsData.map(
        (data) => data.sum.countryMap
    );

    const countriesWithMostRequests =
        reduceCountriesValues(dailyCountriesArray);

    const sortedCountries = topFiveSortedRequests(countriesWithMostRequests);

    const formattedCountries = formatWithFlagAndFullName(sortedCountries);

    const columns = [
        {
            title: "COUNTRY / REGION",
            dataIndex: "country / region",
            key: "country / region",
        },
        {
            title: "TRAFFIC",
            dataIndex: "traffic",
            key: "traffic",
            defaultSortOrder: "descend",
            sorter: (a, b) => a.traffic - b.traffic,
        },
    ];

    const data = formattedCountries.map((country, index) => {
        return {
            key: index,
            "country / region": country.clientCountryName,
            traffic: country.requests,
        };
    });

    return (
        <div className={"requests-table"}>
            <h3 className={"requests-table__title"}>
                Top 5 Traffic Countries / Regions
            </h3>
            <BasicTable data={data} columns={columns} />
        </div>
    );
}

export default RequestsTable;
