// react query
import { useQuery } from "react-query";

// libraries
import axios from "axios";
import moment from "moment";

const today = moment().format("YYYY-MM-DD");
const oneWeekAgo = moment().subtract(7, "days").format("YYYY-MM-DD");

function useGetAnalytics(date_geq = oneWeekAgo, date_leq = today) {
    const URL = "https://api.cloudflare.com/client/v4/graphql";

    const graphQLQuery = `
		{
			viewer {
				zones(filter: {zoneTag: "0f64f1eae203d67172fd9d472c0601af"}) {
					httpRequests1dGroups(filter: {date_geq: "${date_geq}" date_leq: "${date_leq}"}, limit: 364, orderBy: [date_ASC]) {
						dimensions {
							date
						}
						sum {
							bytes
							requests
							pageViews
						countryMap {
							clientCountryName
							requests
							threats
						}
						threats
						}
						uniq {
							uniques
						}
					}
				}
			}
		}
    `;

    async function fetchAnalytics() {
        const res = await axios.post(
            URL,
            { query: graphQLQuery },
            {
                headers: {
                    "X-AUTH-EMAIL": "conadjazzcontest",
                    Authorization:
                        process.env
                            .REACT_APP_CLOUDFLARE_SECRET_TOKEN_DO_NOT_EXPOSE,
                    "Content-Type": "application/json",
                },
            }
        );

        return res.data.data.viewer.zones[0].httpRequests1dGroups;
    }

    const { isLoading, isError, data, error } = useQuery(
        ["analyticsData", date_geq, date_leq],
        fetchAnalytics
    );

    return [isLoading, isError, data, error];
}

export default useGetAnalytics;
