// hooks
import { useState } from "react";
import useGetAnalytics from "../../utils/hooks/useFetchAnalytics";

// components
import ForegroundWrapper from "../../components/ForegroundWrapper/ForegroundWrapper";
import MainTitle from "../mainTitle/MainTitle";
import RequestsChart from "../requestsChart/RequestsChart";
import AttacksChart from "../attacksChart/AttacksChart";
import RequestsTable from "../requestsTable/RequestsTable";
import Spinner from "../../components/Spinner/Spinner";
import BlockSkeleton from "../../components/BlockSkeleton/BlockSkeleton";
import ThreatsBarChart from "../threatsBarChart/ThreatsBarChart";
import Footer from "../footer/Footer";

// moment js
import moment from "moment";

// ant design
import { Alert } from "antd";

// css
import "./Foreground.css";

function Foreground() {
    const [dateLimits, setDateLimits] = useState("");
    const [isLoading, isError, data, error] = useGetAnalytics(
        dateLimits[0],
        dateLimits[1]
    );
    function handleDate(dates) {
        const formattedDates = dates.map((date) =>
            moment(date._d).format("YYYY-MM-DD")
        );
        setDateLimits(formattedDates);
    }

    if (isError) {
        return (
            <div className={"w-full my-5"}>
                <Alert
                    message="Error"
                    description="Could not get data from server"
                    type="error"
                    showIcon
                />
            </div>
        );
    }

    return (
        <ForegroundWrapper>
            <div className={"foreground-main-title"}>
                <MainTitle handleDate={handleDate} />
            </div>

            {isError ? (
                <Alert
                    message="Error"
                    description={error.message}
                    type="error"
                    showIcon
                />
            ) : null}

            <div className={"foreground-requests-graphs"}>
                {!isLoading ? (
                    <RequestsChart analyticsData={data} />
                ) : (
                    <div
                        className={
                            "flex flex-col items-center lg:flex-row lg:justify-around"
                        }
                    >
                        <Spinner />
                        <Spinner />
                        <Spinner />
                    </div>
                )}
            </div>

            <div className={"foreground-basic-table"}>
                {!isLoading ? (
                    <RequestsTable analyticsData={data} />
                ) : (
                    <>
                        <BlockSkeleton />
                    </>
                )}
            </div>

            <div className={"foreground-attacks-threats-graph"}>
                {!isLoading ? (
                    <>
                        <AttacksChart analyticsData={data} />
                        <ThreatsBarChart analyticsData={data} />
                    </>
                ) : (
                    <div
                        className={
                            "w-full flex flex-col items-center lg:flex-row justify-around"
                        }
                    >
                        <Spinner />
                        <Spinner />
                    </div>
                )}
            </div>

            <div className={"foreground-footer"}>
                <Footer />
            </div>
        </ForegroundWrapper>
    );
}

export default Foreground;
