// components
import LineChart from "../../components/LineChart/LineChart";

// icons
import uniqueVisitorsIcon from "../../assets/uniqueVisitorsIcon.png";
import totalRequestsIcon from "../../assets/totalRequestsIcon.png";
import totalDataServedIcon from "../../assets/totalDataServedIcon.png";

// css
import "./RequestsChart.css";

function RequestsChart({ analyticsData }) {
    const fillColor = "rgba(27, 58, 209, 0.8)";
    const dateLabels = analyticsData.map((data) => data.dimensions.date);

    const uniqueVisitors = analyticsData.map(
        (data) => data.uniq.uniques / 1000
    );
    const dataServed = analyticsData.map((data) => data.sum.bytes / 1000000000);
    const totalRequests = analyticsData.map((data) => data.sum.requests / 1000);

    const sumUniqueVisitors = uniqueVisitors.reduce(
        (prev, current) => prev + current,
        0
    );

    const sumDataServed = dataServed.reduce(
        (prev, current) => prev + current,
        0
    );

    const sumTotalRequests = totalRequests.reduce(
        (prev, current) => prev + current,
        0
    );

    return (
        <div className={"requests-graphs"}>
            <h3 className={"requests-graphs__title"}>Requests</h3>
            <div className={"requests-graphs__content"}>
                <LineChart
                    dateLabels={dateLabels}
                    analyticsData={uniqueVisitors}
                    labelName={"Unique Visitors"}
                    fillColor={fillColor}
                    sumInfo={`${sumUniqueVisitors.toFixed(2)}k`}
                    icon={uniqueVisitorsIcon}
                    info={"Daily unique visitors of the web page"}
                />
                <LineChart
                    dateLabels={dateLabels}
                    analyticsData={totalRequests}
                    labelName={"Total Requests"}
                    fillColor={fillColor}
                    sumInfo={`${sumTotalRequests.toFixed(2)}k`}
                    icon={totalRequestsIcon}
                    info={
                        "Total number of requests made to the web page. Usually a unique visitor does multiple requests within the web page"
                    }
                />
                <LineChart
                    dateLabels={dateLabels}
                    analyticsData={dataServed}
                    labelName={"Total Data Served"}
                    fillColor={fillColor}
                    sumInfo={`${sumDataServed.toFixed(2)} GB`}
                    icon={totalDataServedIcon}
                    info={
                        "Total data a server sent to the visitors of the web page"
                    }
                />
            </div>
        </div>
    );
}

export default RequestsChart;
