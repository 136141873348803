// ant design icons
import dot from "../../assets/dot.png";

// css
import "./StatusIcon.css";

function StatusIcon({ status }) {
    return (
        <div
            className={
                status === "online"
                    ? "status-icon"
                    : "status-icon status-icon--offline"
            }
        >
            <div className={"status-icon__info"}>
                {status === "online" ? (
                    <>
                        <img className={"dot"} src={dot} alt={"green dot"} />
                        <p className={"status-icon__content"}>Online</p>
                    </>
                ) : (
                    <>
                        <img className={"dot"} src={dot} alt={"green dot"} />
                        <p
                            className={
                                "status-icon__content status-icon__content--offline"
                            }
                        >
                            Offline
                        </p>
                    </>
                )}
                <p className={"status-icon__content"}></p>
            </div>
        </div>
    );
}

export default StatusIcon;
