// ant design
import { Table } from "antd";

// css
import "./BasicTable.css";

function BasicTable({ columns, data }) {
    return (
        <div className={"basic-table"}>
            <Table columns={columns} dataSource={data} pagination={false} />
        </div>
    );
}

export default BasicTable;
