// ant design
import { Skeleton } from "antd";

function BlockSkeleton() {
    return (
        <div>
            <Skeleton.Input block active size={"large"} />
            <Skeleton.Input block active size={"large"} />
            <Skeleton.Input block active size={"large"} />
            <Skeleton.Input block active size={"large"} />
            <Skeleton.Input block active size={"large"} />
            <Skeleton.Input block active size={"large"} />
            <Skeleton.Input block active size={"large"} />
            <Skeleton.Input block active size={"large"} />
        </div>
    );
}

export default BlockSkeleton;
