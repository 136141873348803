// react query
import { QueryClient, QueryClientProvider } from "react-query";

// views
import Background from "./views/background/Background";

// css
import "./App.scss";

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <div className={"app"}>
                <Background />
            </div>
        </QueryClientProvider>
    );
}

export default App;
