// logos
import mumbleLogo from "../../assets/mumbleLogo.png";

// css
import "./Header.css";

function Header() {
    return (
        <div className={"header"}>
            <img
                className={"header__logo"}
                src={mumbleLogo}
                alt={"mumble logo"}
            />
        </div>
    );
}

export default Header;
