// components
import Header from "../../components/Header/Header";
import BackgroundWrapper from "../../components/BackgroundWrapper/BackgroundWrapper";
import Foreground from "../foreground/Foreground";

// css
import "./Background.css";

function Background() {
    return (
        <div>
            <BackgroundWrapper>
                <Header />

                <div className={"background-align"}>
                    <Foreground />
                </div>
            </BackgroundWrapper>
        </div>
    );
}

export default Background;
