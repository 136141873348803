// components
import StatusIcon from "../../components/StatusIcon/StatusIcon";
import DatePickerWrapper from "../../components/DatePickerWrapper/DatePickerWrapper";

// css
import "./MainTitle.css";

function MainTitle({ handleDate }) {
    return (
        <div className={"main-title"}>
            <div className={"main-title__info"}>
                <h1 className={"main-title__title"}>Status Page</h1>
                <h2 className={"main-title__company-name"}>
                    Conad Jazz Contest
                </h2>

                <StatusIcon status={"online"} />
            </div>
            <div className={"main-title__range-picker"}>
                <DatePickerWrapper handleDate={handleDate} />
            </div>
        </div>
    );
}

export default MainTitle;
