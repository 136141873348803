// momentjs
import moment from "moment";

// ant design
import { DatePicker, Space } from "antd";

// css
import "./DatePickerWrapper.css";

const { RangePicker } = DatePicker;

function DatePickerWrapper({ handleDate }) {
    function disabledDate(current) {
        return (
            current > moment().endOf("day") ||
            current < moment().subtract(365, "days")
        );
    }

    return (
        <Space direction={"vertical"}>
            <RangePicker disabledDate={disabledDate} onChange={handleDate} />
        </Space>
    );
}

export default DatePickerWrapper;
