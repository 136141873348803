// hooks
import { useEffect, useRef, useState } from "react";

// react-chartjs-2
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// css
import "./VerticalBarChart.css";
import LineChartLabel from "../ChartLabel/ChartLabel";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

function VerticalBarChart({
    labels,
    threats,
    options,
    title,
    value,
    icon,
    info,
}) {
    const [chartData, setChartData] = useState({
        datasets: [],
    });
    const chartRef = useRef();

    const fillColor = "rgba(27, 58, 209, 0.8)";

    function createGradient(ctx, area) {
        const colorStart = "rgba(255, 255, 255, 0.8)";
        const colorEnd = fillColor;

        const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

        gradient.addColorStop(0, colorStart);
        gradient.addColorStop(1, colorEnd);

        return gradient;
    }

    // wait for chartRef to access the DOM
    useEffect(() => {
        const chart = chartRef.current;

        if (!chart) {
            return;
        }

        const data = {
            labels,
            datasets: [
                {
                    label: "Threats",
                    data: threats,
                    backgroundColor: createGradient(chart.ctx, chart.chartArea),
                    borderColor: fillColor,
                    fill: true,
                    lineTension: 0.5,
                    pointRadius: 2.5,
                },
            ],
        };

        setChartData(data);
    }, [threats]);

    return (
        <div className={"bar-chart"}>
            <LineChartLabel
                title={title}
                value={value}
                icon={icon}
                info={info}
            />
            <div className={"bar-chart__container"}>
                <Bar ref={chartRef} data={chartData} options={options} />
            </div>
        </div>
    );
}

export default VerticalBarChart;
