/*
 * create an array of countries with the requests and threats
 * */
export function reduceCountriesValues(dailyCountriesArray) {
    // create an array of all the countries requested the website in the selected range
    function getAllCountries() {
        let supportArray = [];

        dailyCountriesArray.forEach((daily) => {
            daily.forEach((country) => {
                if (
                    supportArray.filter(
                        (e) => e.clientCountryName === country.clientCountryName
                    ).length > 0
                ) {
                    return;
                } else {
                    supportArray = [
                        ...supportArray,
                        {
                            clientCountryName: country.clientCountryName,
                            requests: 0,
                            threats: 0,
                        },
                    ];
                }
            });
        });

        return supportArray;
    }

    // get the sum of all the requests for every country in the selected range
    function sumRequests() {
        const countriesArray = getAllCountries();

        dailyCountriesArray.forEach((daily) => {
            daily.forEach((country) => {
                countriesArray.forEach((name) => {
                    if (country.clientCountryName === name.clientCountryName) {
                        name.requests += country.requests;
                        name.threats += country.threats;
                    }
                });
            });
        });

        return countriesArray;
    }

    return sumRequests();
}

/*
 * return the top 5 countries with most request sorted
 * */
export function topFiveSortedRequests(countriesArray) {
    const sortedCountries = countriesArray.sort(
        (a, b) => b.requests - a.requests
    );

    return sortedCountries.filter((country, index) => index < 5);
}

/*
 * return the top 5 countries with most threats sorted
 * */
export function topFiveSortedThreats(countriesArray) {
    const sortedCountries = countriesArray.sort(
        (a, b) => b.threats - a.threats
    );

    return sortedCountries.filter((country, index) => index < 5);
}

/*
 * return the country full name and flag
 * */
export function formatWithFlagAndFullName(topCountriesArray) {
    return topCountriesArray.map((country) => {
        if (country.clientCountryName === "T1") {
            return {
                clientCountryName: "Tor Network",
                requests: country.requests,
                threats: country.threats,
            };
        }

        const regionNamesInEnglish = new Intl.DisplayNames(["EN"], {
            type: "region",
        });

        const codePoints = country.clientCountryName
            .toUpperCase()
            .split("")
            .map((char) => 127397 + char.charCodeAt());

        return {
            clientCountryName: `${regionNamesInEnglish.of(
                country.clientCountryName
            )} ${String.fromCodePoint(...codePoints)}`,
            requests: country.requests,
            threats: country.threats,
        };
    });
}
