// ant design
import { Tooltip } from "antd";

// ant design icons
import { InfoCircleOutlined } from "@ant-design/icons";

// css
import "./ChartLabel.css";

function ChartLabel({ title, value, icon, info }) {
    return (
        <div className={"line-chart-label"}>
            <div className={"icon"}>
                <img src={icon} alt={"unique requests icon"} />
            </div>
            <div className={"line-chart-label__info"}>
                <div className={"flex items-center"}>
                    <h5 className={"line-chart-label__title"}>{title}</h5>
                    <Tooltip className={"mx-3"} title={info} autoAdjustOverflow>
                        <InfoCircleOutlined
                            style={{
                                color: "rgba(27, 58, 209, 0.8)",
                                fontSize: "20px",
                            }}
                        />
                    </Tooltip>
                </div>
                <h3 className={"line-chart-label__value"}>{value}</h3>
            </div>
        </div>
    );
}

export default ChartLabel;
