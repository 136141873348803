// icons
import mumbleLogo2 from "../../assets/mumbleLogo2.png";
import checkCircle from "../../assets/checkCircle.png";

//css
import "./Footer.css";

function Footer() {
    return (
        <div className={"footer"}>
            <div className={"footer__logo"}>
                <p>Powered by</p>
                <img src={mumbleLogo2} alt={"mumble logo"} />
            </div>

            <div className={"footer__checked"}>
                <img src={checkCircle} alt={"checked circle"} />
                <p>Live Checking</p>
            </div>
        </div>
    );
}

export default Footer;
