// ant design
import { Spin } from "antd";

// css
import "./Spinner.css";

function Spinner() {
    return (
        <div className={"spinner"}>
            <Spin size={"large"} />
        </div>
    );
}

export default Spinner;
